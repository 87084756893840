<div class="filter-box"
     tabindex="0">
  <mat-form-field class="search-field">
    <mat-label>Поиск</mat-label>
    <input matInput 
           placeholder="Поиск" 
           [(ngModel)]="searchTerm" 
           (ngModelChange)="filterCustomers()" 
           #inputElement />
    <button mat-icon-button matSuffix>
      <mat-icon>search</mat-icon>
    </button>
  </mat-form-field>

  <div class="scrollable-list">
    <mat-checkbox *ngFor="let customer of filteredCustomers" 
                  [checked]="selectedCustomers.includes(customer.id)" 
                  (change)="onCheckboxChange(customer.id, $event.checked)">
      {{ customer.name }}
    </mat-checkbox>
    <mat-checkbox *ngIf="filteredCustomers.length === 0" disabled>
      Нет данных
    </mat-checkbox>
  </div>

  <button mat-raised-button color="warn" (click)="resetSelection()" [disabled]="selectedCustomers.length === 0">
      {{ 'ALLORDERSLIST.RESET' | translate }}
  </button>
</div>

import {Component, OnInit, ViewChild} from '@angular/core';
import {all_orders, finished, order_fields, orderinfo, orderlist, orders_row_operations} from '../../locale/locale';
import {LocaleService} from '../../../../components/locale/locale.service';
import {DispatcherOrderWriteAccess} from '../../../../components/access/dispatcher-order-write';
import {
    TableOperationsController
} from '../../../../components/table-operations-controller/table-operations-controller';
import {defaultTableOptions, TableComponent, TableOptions} from '../../../../components/table/table.component';
import {TableData} from '../../../../components/table/tabledata';
import {TranslateService} from '@ngx-translate/core';
import {AllListTabledata} from './all-list-tabledata';
import {AllOrdersTableOperationsFactory} from './all-list-operation-factory';
import {OrdersDialogPresenterService} from '../../dialog/ordersdialogspresenter/orders-dialog-presenter.service';
import {MatDialog} from '@angular/material/dialog';
import {common, default_row_operations, equipment, offers, orders, tripStage, units} from '../../../locale/locale';

const locales = [
    all_orders,
    orderlist,
    orders,
    common,
    default_row_operations,
    orders_row_operations,
    orderinfo,
    tripStage,
    offers,
    equipment,
    finished,
    order_fields,
    units
];

@Component({
    selector: 'app-all',
    templateUrl: './all.component.html',
    styleUrls: ['./all.component.scss']
})
export class AllComponent implements DispatcherOrderWriteAccess.TableOperationUpdating {
    tableData: TableData;
    tableOptions: TableOptions;
    headerButtons = [];
    operationsController: TableOperationsController;
    @ViewChild(TableComponent) private table: TableComponent;

    constructor(
        private localeService: LocaleService,
        public dialog: MatDialog,
        private translate: TranslateService,
        private dialogPresenter: OrdersDialogPresenterService
    ) {
        this.localeService.loadLocales(locales);

        this.tableData = new AllListTabledata(this.translate);
        this.tableOptions = defaultTableOptions();
        this.tableOptions.alwaysVisibleHeader = true;

        this.headerButtons = this.makeHeaderButtons();
        let operationsFactory = new AllOrdersTableOperationsFactory(
            this.dialogPresenter
        );
        this.operationsController = new TableOperationsController(operationsFactory);
    }

    ngAfterViewInit() {
        DispatcherOrderWriteAccess.checkTableOperations(this);
    }

    search(searchString: string) {
        this.table.search(searchString);
    }

    updateOperations() {
        this.operationsController.updateOperations(this.table);
    }

    private reloadData() {
        this.table.reloadData();
        DispatcherOrderWriteAccess.reloadTableOperations(this);
    }

    private filtersReset() {
        this.table.filtersReset();
    }


    private makeHeaderButtons() {
        return [
            {
                'title': 'COMMON.BUTTONS.RELOAD',
                'action': () => this.reloadData()
            },
            {
                'title': 'COMMON.BUTTONS.RESET_FILTERS',
                'action': () => this.filtersReset()
            }
        ];
    }

}

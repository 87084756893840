import {TableData, TableSort} from '../../../../components/table/tabledata';
import {OrderColumns} from '../../helpers/order-columns-factory';
import {CurrentUser} from '../../../../components/data/core/models/base/user';
import {ServerTableDataSource} from '../../../../components/table/data_sources/server-data-source';
import {OrderQuerySearchFilter} from '../../helpers/order-query-search-filter';
import {PFQuery} from '../../../../components/data/core/models/base/query';
import * as AccessQueries from '../../../../components/access/query';
import {
    LiveQueryInclusion,
    LiveQueryModifier,
    LiveQuerySubscriber
} from '../../../../components/table/data_sources/live-query-subscriber';
import {TranslateService} from '@ngx-translate/core';
import {QueryResultFilter} from '../../../../components/access/queryResultFilter';
import {TreeNode} from "../../../../components/hierarchical-checkbox/tree-node.model";
import {TableNameKeys} from "../../../../components/table/data_sources/table-name-keys";

export class AllListTabledata extends TableData {

    constructor(
        private translate: TranslateService,
    ) {
        super();

        let columns = [
            OrderColumns.makeColumnNumber()
        ];

        let userColumns;
        if (CurrentUser.isCustomer()) {
            userColumns = [
                OrderColumns.makeColumnUnloadPoint(),
                OrderColumns.makeColumnBrand(),
                OrderColumns.makeColumnTonnage(),
                OrderColumns.makeColumnUnloadDate(this.translate.currentLang),
                OrderColumns.makeColumnCustomerStatus(this.translate),
            ];
        } else {
            userColumns = [
                OrderColumns.makeColumnCustomer(),
                OrderColumns.makeColumnUnloadDate(this.translate.currentLang),
                OrderColumns.makeColumnUnloadPoint(),
                OrderColumns.makeColumnTonnage(),
                OrderColumns.makeColumnFacticalLoadDate(),
                OrderColumns.makeColumnDriverStatus(this.translate),
                OrderColumns.makeColumnEquipment(this.translate),
            ];

            if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
                userColumns.push(
                    OrderColumns.makeColumnAuthorName()
                );
            }
        }
        userColumns.push(OrderColumns.makeColumnComment());

        columns = columns.concat(userColumns);

        this.setColumns(columns);

        let includeFields = [
            'unloadingPoint',
            'offers.trip.historyRecords',
            'offers.trip.transportUnit.driver',
            'offers.transportUnit.driver',
            'offers.transportUnit.vehicle.model.brand',
            'offers',
            'articleBrand.type',
            'customer',
            'unloadingEntrance',
            'carriers',
            'carrierOffers.driver',
            'loadingEntrance',
            'comment',
        ];

        if (CurrentUser.isCustomer()) {
            includeFields.push('unloadingPoint.manager');
        } else {
            includeFields = includeFields.concat([
                'loadingPoint',
                'intermediary',
                'supplier',
            ]);
        }

        if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
            includeFields = includeFields.concat([
                'author',
                'author.manager',
                'author.dispatcher',
                'author.customer',
                'author.logistician',
            ]);
        }


        let sort: TableSort = new TableSort('number', 'desc');
        let tableDataSource = new ServerTableDataSource('Order', includeFields);
        if (!CurrentUser.isCustomer()) {
            tableDataSource.loadAllCustomers();
        }
        if (CurrentUser.isAdministrator() || CurrentUser.isLogistician()) {
            tableDataSource.loadUniqueAuthors();
        }
        tableDataSource.setDefaultSort(sort);
        tableDataSource.queryFilter = (filter, query) => {
            let searchQuery;
            let unloadingEntranceAddressFilter = OrderQuerySearchFilter.unloadingEntranceAddress(filter);

            if (CurrentUser.isCustomer()) {
                let brandNameFilter = OrderQuerySearchFilter.brandName(filter);
                searchQuery = PFQuery.or(
                    brandNameFilter,
                    unloadingEntranceAddressFilter,
                );
                let tonnage = parseFloat(filter);
                if (!isNaN(tonnage)) {
                    let tonnageFilter = OrderQuerySearchFilter.expectedTonnage(tonnage);
                    searchQuery = PFQuery.or(searchQuery, tonnageFilter);
                }
            } else {
                let customerNameFilter = OrderQuerySearchFilter.customerName(filter);
                searchQuery = PFQuery.or(
                    customerNameFilter,
                    unloadingEntranceAddressFilter,
                );
            }

            let number = parseFloat(filter);
            if (!isNaN(number)) {
                let numberFilter = OrderQuerySearchFilter.orderNumber(number);
                searchQuery = PFQuery.or(searchQuery, numberFilter);
            }
            return searchQuery;
        };

        function applyQueryModification(query: PFQuery) {
            if (CurrentUser.isCustomer()) {
                query = AccessQueries.containsCurrentCustomer(query);
            } else if (CurrentUser.isDispatcher()) {
                query = AccessQueries.containsCurrentCarrier(query);
            }
            else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
                query = AccessQueries.authorMatchesCurrentUser(query);
                let managerAssignedOrders = new PFQuery('Order');
                managerAssignedOrders = AccessQueries.managerMatchesCurrentUser(managerAssignedOrders);
                query = PFQuery.or(query, managerAssignedOrders);
            }
            query = AccessQueries.filterDeleted(query);
            return query;
        }

        tableDataSource.queryModify = function(query: PFQuery): PFQuery {
            return applyQueryModification(query);
        };

        let subscriber = new LiveQuerySubscriber('Order', includeFields);

        subscriber.liveQueryModifier = new LiveQueryModifier(
            function(query) {
                return applyQueryModification(query);
            }
        );

        let filter = function(object) {
            if (CurrentUser.isDispatcher()) {
                if (!QueryResultFilter.transportUnitMatchesCurrentCarrier(object.transportUnit())) {
                    return null;
                }
            } else if (!CurrentUser.isAdministrator() && !CurrentUser.isLogistician()) {
                if (!QueryResultFilter.orderAuthorMatchesCurrentUser(object.transportUnit())) {
                    return null;
                }
            }
            return object;
        };

        subscriber.liveQueryInclusions = [
            new LiveQueryInclusion('Offer', new LiveQueryModifier(null, filter)),
            new LiveQueryInclusion('Trip', new LiveQueryModifier(null, filter), ['historyRecords']),
        ];

        tableDataSource.setLiveQuerySubscriber(subscriber);
        this.setProvider(tableDataSource);
    }

    canSortColumn(column) {
        switch (column.key) {
            case TableNameKeys.unloadDate:
            case TableNameKeys.loadDateFact:
            case TableNameKeys.customer:
            case TableNameKeys.number:
            case TableNameKeys.createdAt:
            case TableNameKeys.tonnage:
                return true;
            default:
                return false;
        }
    }

    canSortByDateColumn(column) {
        switch (column.key) {
            case TableNameKeys.unloadDate:
            case TableNameKeys.loadDateFact:
            case TableNameKeys.customer:
            case TableNameKeys.author:
            case TableNameKeys.driverStatus:
                return false;
            default:
                return true;
        }
    }

    getStatusFilterData()
    {
        let treeData: TreeNode[] = [
            {
                name: 'Завершенные',
                checked: false,
            },
            {
                name: 'В процессе',
                checked: false,
                children: [
                    { name: "Выехал под загрузку", checked: false },
                    { name: "Прибыл в пункт загрузки", checked: false },
                    { name: "Загрузился", checked: false },
                    { name: "Выехал в пункт разгрузки", checked: false },
                    { name: "Прибыл в пункт разгрузки", checked: false },
                ]
            },
            {
                name: 'Новые',
                checked: false,
                children: [
                    { name: 'Принят', checked: false },
                    { name: 'Не принят', checked: false },
                    { name: 'В очереди', checked: false },
                    { name: 'Отказался', checked: false }
                ]
            },
            {
                name: 'Согласованность',
                checked: false,
                children: [
                    { name: 'Не согласован', checked: false },
                    { name: 'Согласован', checked: false }
                ]
            }
        ];
        return treeData;
    }
}

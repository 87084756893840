<div class="page-layout carded fullwidth" fusePerfectScrollbar>

    <div class="top-bg primary"></div>
      <div class="center">
          <table-header headerTitle="{{'FINISHEORDERSLIST.TITLE' | translate}}" 
                        iconName="done_all" 
                        searchBarTitle="COMMON.SEARCHTITLE"
                        [buttons]="headerButtons"
                        (onSearch)="search($event)"></table-header>
          <div class="mat-elevation-z8 fuse-white mb-16" fxLayout="column">
            <div class="mx-20">
              <div class="mt-8"
               fxLayout="column" fxLayoutAlign="center center"
               fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="start center">

                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-label>{{'FINISHEORDERSLIST.DATE_FROM' | translate}}</mat-label>
                  <div class="filter-field primary-50 mx-16" fxLayout="row" fxLayoutAlign="space-between center" style="height: 30px;">
                    <input #startDate
                           class="ml-8"
                           matInput
                           type="text"
                           [matDatepicker]="startDatePicker"
                           (dateChange)="startDateChanged()"
                           (click)="startDatePicker.open()">
                    <mat-icon class="s-20 mr-8" (click)="startDatePicker.open()">calendar_today</mat-icon>
                    <mat-datepicker #startDatePicker></mat-datepicker>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center">
                  <mat-label>{{'FINISHEORDERSLIST.DATE_TO' | translate}}</mat-label>
                  <div class="filter-field primary-50 mx-16" fxLayout="row" fxLayoutAlign="space-between center" style="height: 30px;">
                    <input #endDate
                           class="ml-8"
                           matInput
                           type="text"
                           [matDatepicker]="endDatePicker"
                           (dateChange)="updateFilter()"
                           (click)="endDatePicker.open()">
                    <mat-icon class="s-20  mr-8" (click)="endDatePicker.open()">calendar_today</mat-icon>
                    <mat-datepicker #endDatePicker></mat-datepicker>
                  </div>
                </div>
                <button *ngIf="endDate.value || startDate.value" 
                        mat-raised-button 
                        type="button"
                        (click)="resetFilter()">
                        {{'FINISHEORDERSLIST.RESET' | translate}}
                </button>
              </div>
              <mat-error *ngIf="startDate.value && endDate.value && !isDateValid">{{'FINISHEORDERSLIST.ERROR.DATE_TO_LOW' | translate}}</mat-error>
            </div>

            <div fxLayout="column" fxFlex>
              <action-bar [operations]="operationsController.operations" class="sticky fuse-white"></action-bar>
              <cd-table [tabledata]="tableData"
                        [options]="tableOptions"
                        (rowDoubleClick)="operationsController.doubleClickHandler($event)"
                        (onSelectionChange)="operationsController.updateOperations($event.table)">
              </cd-table>
            </div>
            
          </div>
    </div>
</div>

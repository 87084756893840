import { NgModule } from '@angular/core';
import { CustomerSelectComponent }  from './customer-select.component'
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import { TranslateModule } from '@ngx-translate/core';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    declarations: [
        CustomerSelectComponent,
    ],
    imports: [
        FuseSharedModule,
        TranslateModule,
        MaterialModule,
        MatDialogModule,
    ],
    exports: [
        CustomerSelectComponent,
    ],
    entryComponents: [CustomerSelectComponent],
})

export class CustomerSelectModule {}

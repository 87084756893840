import { CloudExecutor } from './cloud-executor';

export class NewListCloudExecutor extends CloudExecutor {
    getOrders(params: { offset: number, count: number } | any = null) {
        return this.run('newTable_listOrders', params)
            .catch((error) => {
                var errorDescription;
                if (error == 'NoSupplierForArticle') {
                    errorDescription = 'NoSupplierForArticle';
                } else {
                    errorDescription = 'Undefined'
                }
                return Promise.reject(errorDescription);
            })
    }


}
import Parse from 'parse';
import {BehaviorSubject, Observable} from 'rxjs';
import {TableDataSource, FieldFilter, FilterCondition} from '../table.component';
import {PFQuery} from 'app/components/data/core/models/base/query';
import {PFObject} from 'app/components/data/core/models/base/object';
import {LiveQuerySubscriber} from './live-query-subscriber';
import {Sort} from '@angular/material/sort';
import {Directive} from '@angular/core';
import {Order, getOrderAuthorName, getOrderAuthorID} from 'app/components/data/core/models/order/order';
import {TripStage} from "../../data/core/models/trip/trip";
import {Configuration} from "../../data/core/models/config/configuration";
import {NewListCloudExecutor} from "../../data/cloud-executors/new-list-executor";

export type TableDataQueryModify = (query: PFQuery) => PFQuery;
export type TableDataQueryFilter = (filterStr: string, query: PFQuery) => PFQuery;

@Directive()
export class ServerTableDataSource implements TableDataSource {

    data = new BehaviorSubject<Parse.Object[]>([]);
    dataCustomers = new BehaviorSubject<Parse.Object[]>([]);
    dataAuthors = new BehaviorSubject<Parse.Object[]>([]);
    dataSuppliers = new BehaviorSubject<Parse.Object[]>([]);
    private loading: boolean = false;
    public lastLoadError;

    public queryModify: TableDataQueryModify;
    public queryFilter: TableDataQueryFilter;

    private count: number = 0;
    private subscribedTabledata;

    private filter: string = '';
    private otherFilters: FieldFilter[];
    private treeNodeFilters: FieldFilter[];

    private subscriber: LiveQuerySubscriber;

    private defaultSort: Sort = null;
    private sort: Sort = null;

    constructor(
        private tableName: string,
        private includedFields: string[] = []
    ) {
    }

    ngOnDestroy() {
        this.unsubscribe();
    }

    // DataSource Interface
    connect(): Observable<Parse.Object[]> {
        return this.data.asObservable();
    }

    disconnect(): void {
        this.data.complete();
    }

    // TableDataSource Interface
    hasData(): boolean {
        let value = this.data.getValue();
        return value && value.length > 0;
    }

    totalDataCount(): number {
        return this.count;
    }

    setSearchFilter(value: string) {
        this.filter = value;
    }

    setOtherFilters(filters: FieldFilter[]) {
        this.otherFilters = filters;
    }

    setNodeTreeFilters(filters: FieldFilter[]) {
        this.treeNodeFilters = filters;
    }

    getData() {
        return this.data.getValue();
    }

    refresh() {
        this.data.next(this.getData());
    }

    loadData(offset: number, count: number) {
        this.loading = true;
        this.data.next([]);
        this.lastLoadError = null;

        let query = this.constructQuery();
        query.skip(offset);
        if (count <= 0) {
            count = 1000;
        }
        query.limit(count);
        query.withCount(true);
        query.includes(this.includedFields);
        console.log(query);
        query.find().then((data) => {
            this.count = data.count;
            this.data.next(data.results);
            this.loading = false;
        }).catch(error => {
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    isLoading(): boolean {
        return this.loading;
    }

    getLastLoadError(): string {
        return this.lastLoadError;
    }

    setDefaultSort(sort: Sort) {
        this.defaultSort = sort;
    }

    setSort(sort: Sort) {
        this.sort = sort;
    }

    // Class Public methods

    setLiveQuerySubscriber(subscriber: LiveQuerySubscriber) {
        if (this.subscriber) {
            this.unsubscribe();
        }
        this.subscriber = subscriber;
        this.subscribe();
    }

    loadAllCustomers(): void {
        this.loading = true;
        this.lastLoadError = null;

        const query = new PFQuery('Customer');

        query.find().then((customers) => {
            this.dataCustomers.next(customers);
            this.loading = false;
        }).catch((error) => {
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    loadAllSuppliers(): void {
        this.loading = true;
        this.lastLoadError = null;

        const query = new PFQuery('Supplier');

        query.find().then((suppliers) => {
            this.dataSuppliers.next(suppliers);
            this.loading = false;
        }).catch((error) => {
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    loadUniqueAuthors(): void {
        this.loading = true;
        this.lastLoadError = null;

        const orderQuery = new PFQuery('Order');

        orderQuery.includes([
            'author',
            'author.manager',
            'author.dispatcher',
            'author.customer',
            'author.logistician',
        ]);
        orderQuery.find().then((orders) => {
            const authorNamesPromise = Promise.all(
                orders.map(order => {
                    const id = getOrderAuthorID(order);
                    const name = getOrderAuthorName(order);
                    return {id, name};
                })
            );

            authorNamesPromise.then(authors => {
                // Фильтруем уникальные записи и исключаем 'Admin'
                const uniqueAuthorsMap = new Map();
                authors.forEach(({id, name}) => {
                    if (name !== 'Admin') {
                        uniqueAuthorsMap.set(id, name);
                    }
                });

                // Преобразуем Map обратно в массив объектов
                const uniqueAuthors = Array.from(uniqueAuthorsMap.entries()).map(([id, name]) => ({
                    id,
                    name
                }));
                this.loading = false; // Устанавливаем состояние загрузки в false
                this.dataAuthors.next(uniqueAuthors);
            });
            //

        }).catch((error) => {
            // Обработка ошибок
            this.lastLoadError = error;
            this.loading = false;
        });
    }

    // Private 

    private constructQuery(): PFQuery {
        let orderQuery = new PFQuery(this.tableName);
        if (this.filter != undefined && this.filter != '' && this.queryFilter != undefined) {
            orderQuery = this.queryFilter(this.filter, orderQuery);
        }
        if (this.otherFilters) {
            this.otherFilters.forEach((filter: FieldFilter) => {
                switch (filter.condition) {
                    case FilterCondition.Equal:
                        orderQuery.equalTo(filter.field, filter.value);
                        break;
                    case FilterCondition.In: // обработка условия "в массиве"
                        orderQuery.containedIn(filter.field, filter.value);
                        break;
                    case FilterCondition.GreaterThan:
                        orderQuery.greaterThan(filter.field, filter.value);
                        break;
                    case FilterCondition.GreaterThanOrEqual:
                        orderQuery.greaterThanOrEqualTo(filter.field, filter.value);
                        break;
                    case FilterCondition.LessThan:
                        orderQuery.lessThan(filter.field, filter.value);
                        break;
                    case FilterCondition.LessThanOrEqual:
                        orderQuery.lessThanOrEqualTo(filter.field, filter.value);
                        break;
                    case FilterCondition.SubQuery:
                        const subQuery = new PFQuery(filter.additionalField); // Таблица для подзапроса
                        if (filter.orConditions && filter.orConditions.length > 0) {
                            const groupedConditions: { [key: string]: Array<any> } = {};
                            filter.orConditions.forEach((cond) => {
                                if (!groupedConditions[cond.subField]) {
                                    groupedConditions[cond.subField] = [];
                                }
                                groupedConditions[cond.subField].push(cond.condition);
                            });
                            const andQueries = Object.keys(groupedConditions).map((field) => {
                                const fieldConditions = groupedConditions[field].map((value) => {
                                    const orQuery = new PFQuery(filter.additionalField);
                                    orQuery.equalTo(field, value);
                                    return orQuery;
                                });
                                return PFQuery.or(...fieldConditions);
                            });
                            const finalSubQuery = andQueries.reduce((prev, current) => {
                                if (!prev) return current;
                                return PFQuery.and(prev, current);
                            });
                            orderQuery.matchesQuery(filter.field, finalSubQuery); // Применение итогового подзапроса
                        }
                        break;
                    case FilterCondition.StatusFilters: {
                        let newQuery = new Parse.Query("Offer");

                        if (filter.value[3].length > 0) {
                            // Убираем undefined из массива
                            const filteredValues = filter.value[3].filter(value => value !== undefined);

                            // Проверяем, был ли undefined в массиве
                            const containsUndefined = filter.value[3].includes(undefined);

                            if (containsUndefined && filteredValues.length > 0) { //Если у нас есть и обычные элементы и undefined
                                // Создаем запрос для containedIn с отфильтрованным массивом
                                const containedInQuery = new Parse.Query("Offer");
                                containedInQuery.containedIn("queueStatus", filteredValues);

                                // Создаем запрос для doesNotExist
                                const doesNotExistQuery = new Parse.Query("Offer");
                                doesNotExistQuery.doesNotExist("queueStatus");

                                // Объединяем запросы через OR
                                newQuery = Parse.Query.or(containedInQuery, doesNotExistQuery);
                            } else if (containsUndefined) //если у нас есть только undefined
                            {
                                newQuery.doesNotExist("queueStatus");
                            } else { //если есть только обычные
                                // Если undefined нет, просто используем containedIn
                                newQuery.containedIn("queueStatus", filteredValues);
                            }
                        }


                        let declinedQuery = new Parse.Query("Offer");
                        if (filter.value[2].length > 0) {
                            declinedQuery.containedIn("declined", filter.value[2]);
                        }

                        // Вложенный запрос на поиск поездок с stage == '1'
                        let tripQuery = new Parse.Query("Trip");
                        if (filter.value[0].length > 0) {
                            tripQuery.containedIn("stage", filter.value[0]);
                        }

                        let offerWithTripQuery = new Parse.Query("Offer");
                        offerWithTripQuery.matchesQuery("trip", tripQuery);

                        if (filter.value[3].length > 0 && filter.value[2].length > 0 && filter.value[0].length > 0) {
                            let combinedQuery = Parse.Query.or(newQuery, declinedQuery, offerWithTripQuery);
                            orderQuery.matchesQuery("offers", combinedQuery);
                        } else if (filter.value[3].length > 0 && filter.value[2].length > 0) {
                            let combinedQuery = Parse.Query.or(newQuery, declinedQuery);
                            orderQuery.matchesQuery("offers", combinedQuery);
                        } else if (filter.value[3].length > 0 && filter.value[0].length > 0) {
                            let combinedQuery = Parse.Query.or(newQuery, offerWithTripQuery);
                            orderQuery.matchesQuery("offers", combinedQuery);
                        } else if (filter.value[2].length > 0 && filter.value[0].length > 0) {
                            let combinedQuery = Parse.Query.or(declinedQuery, offerWithTripQuery);
                            orderQuery.matchesQuery("offers", combinedQuery);
                        } else {
                            if (filter.value[3].length > 0)
                                orderQuery.matchesQuery("offers", newQuery);
                            if (filter.value[2].length > 0)
                                orderQuery.matchesQuery("offers", declinedQuery);
                            if (filter.value[0].length > 0)
                                orderQuery.matchesQuery("offers", offerWithTripQuery);
                        }


                        if (filter.value[1].length > 0) {
                            // Создаем запрос для условия consistency == "agree"
                            let consistencyQuery = new PFQuery(this.tableName);
                            consistencyQuery.containedIn("consistency", filter.value[1]);

                            // Объединяем запросы с помощью PFQuery.or
                            orderQuery = PFQuery.or(orderQuery, consistencyQuery);
                        }
                        break;
                    }
                    case FilterCondition.TripStageDateRange: {
                        const [stage, startDate, endDate] = filter.value;

                        const historyRecordQuery = new PFQuery('TripHistoryRecord');
                        historyRecordQuery.equalTo('stage', stage);
                        historyRecordQuery.greaterThanOrEqualTo('date', startDate);
                        historyRecordQuery.lessThanOrEqualTo('date', endDate);

                        const tripQuery = new PFQuery('Trip');
                        tripQuery.matchesQuery('historyRecords', historyRecordQuery);

                        const offersQuery = new PFQuery('Offer');
                        offersQuery.matchesQuery('trip', tripQuery);

                        orderQuery.matchesQuery('offers', offersQuery);
                        break;
                    }
                }
            })
        }

        if (this.queryModify) {
            orderQuery = this.queryModify(orderQuery);
        }

        let currentSort;
        if (this.sort && this.sort.active !== undefined && this.sort.direction != '') {
            currentSort = this.sort;
        } else if (this.defaultSort) {
            currentSort = this.defaultSort;
        }

        if (currentSort) {
            switch (currentSort.direction) {
                case 'asc':
                    orderQuery.addAscending(currentSort.active)
                    break;
                case 'desc':
                    orderQuery.addDescending(currentSort.active)
                    break;
            }
        }
        return orderQuery;
    }

    private removeObject(fromData: Parse.Object[], object: Parse.Object) {
        let index = fromData.findIndex(o => {
            return PFObject.compareFn(o, object);
        });
        if (index != -1) {
            fromData.splice(index, 1);
        }
        return fromData;
    }

    private addObject(toData: Parse.Object[], object: Parse.Object) {
        let index = toData.findIndex(o => {
            return PFObject.compareFn(o, object);
        });
        if (index == -1) {
            toData.unshift(object);
        }
        return toData;
    }

    private commitChanges(objects: Parse.Object[]) {
        this.data.next(objects);
        this.count = objects.length;
    }

    private subscribe(): void {
        this.unsubscribe();
        if (this.subscriber != null) {
            this.subscriber.subscribe((oldObject, newObject) => {
                if (PFObject.compareFn(oldObject, newObject)) {
                    return;
                } else {
                    let orders = this.data.getValue();
                    if (oldObject != null) {
                        orders = this.removeObject(orders, oldObject);
                    }
                    if (newObject != null) {
                        orders = this.addObject(orders, newObject);
                    }
                    this.commitChanges(orders);
                }
            });
        }
    }

    private unsubscribe(): void {
        if (this.subscribedTabledata != null) {
            this.subscribedTabledata.subscriber.unsubscribe();
            this.subscribedTabledata = null;
        }
    }
}
import {Component, EventEmitter, Input, Output, ViewChild, ElementRef, QueryList, ViewChildren} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';

import { TableData } from './tabledata';
import { SelectionModel, DataSource } from "@angular/cdk/collections";
import { tap } from 'rxjs/operators';
import { BehaviorSubject, merge } from 'rxjs';
import * as _ from 'lodash';

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { PFObject } from '../data/core/models/base/object';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { CustomerSelectComponent } from 'app/main/customer/customer-select/customer-select.component';
import { TreeNode } from 'app/components/hierarchical-checkbox/tree-node.model';
import { HierarchicalCheckboxComponent } from 'app/components/hierarchical-checkbox/hierarchical-checkbox.component';
import {MatDialog} from "@angular/material/dialog";
import {TripStage} from "../data/core/models/trip/trip";
import {TableNameKeys} from "./data_sources/table-name-keys";

export enum FilterCondition {
  Equal,
  In,
  GreaterThan,
  LessThan,
  GreaterThanOrEqual,
  LessThanOrEqual,
  SubQuery,
  StatusFilters,
  TripStageDateRange,
}

export class FieldFilter {
  constructor(
      public field: string, // Поле, на которое накладывается фильтр
      public value: any, // Значение фильтра или объект с условиями
      public condition: FilterCondition = FilterCondition.Equal, // Условие фильтрации
      public additionalField: string = null, // Поле для подзапроса
      public orConditions: Array<{ subField: string; condition: any }> = [], // Условия "или" внутри подзапроса
  ) { }
}


export interface ClickData {
  data: any,
  table: TableComponent
}

export interface TableDataSource extends DataSource<PFObject> {
  data: BehaviorSubject<PFObject[]>
  dataCustomers: BehaviorSubject<PFObject[]>
  dataAuthors: BehaviorSubject<PFObject[]>
  dataSuppliers: BehaviorSubject<PFObject[]>
  hasData(): boolean;
  totalDataCount(): number;
  getData(): any[];
  loadData(offset: number, count: number);
  loadAllCustomers(): void;
  refresh(): void;
  setSearchFilter(value: string);
  setOtherFilters(filters: FieldFilter[]);
  setNodeTreeFilters(filters: FieldFilter[]);
  isLoading(): boolean;
  getLastLoadError(): string;
  setDefaultSort(sort: Sort);
  setSort(sort: MatSort);
}

export interface TableSelection {
  select(row);
  deselect(row);
  getSelected(): any[];
  toggle(row);
  isSelected(row): boolean;
  clear();
}

export interface TableOptions {
  hiddenHeader?: boolean;
  alwaysVisibleHeader?: boolean;
  hiddenPaginator?: boolean;
  singleSelection?: boolean;
  selectable?: boolean;
  dragable?: boolean;
}

export function defaultTableOptions() {
  return { hiddenHeader: false, alwaysVisibleHeader: false, hiddenPaginator: false, singleSelection: false, selectable:true, dragable: false }
}
const DATE_FORMAT = "DDMMYYYY";
@Component({
  selector: 'cd-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})

export class TableComponent {
  @Input() set filter(value: string) {
    this.search(value);
  };
  @Input() noItemsTitle: string;
  @Input() options: TableOptions = defaultTableOptions();
  @Input() disabled: boolean;
  @Input() set tabledata(data: TableData) {
    this.setTableData(data);
  };

  @Output() rowDoubleClick = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter();

  private _tabledata: TableData;
  private dataSource: TableDataSource;

  public selection: TableSelection = new DefaultTableSelection();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('hiddenCustomerField', { read: ElementRef, static: false }) hiddenCustomerField!: ElementRef;

  @ViewChild('hiddenAuthorField', { read: ElementRef, static: false }) hiddenAuthorField!: ElementRef;

  @ViewChild('hiddenSupplierField', { read: ElementRef, static: false }) hiddenSupplierField!: ElementRef;

  @ViewChild('hiddenStatusField', { read: ElementRef, static: false }) hiddenStatusField!: ElementRef;

  @ViewChild('hiddenFormField', { static: false }) hiddenInput!: ElementRef;
  @ViewChild('dateRangePicker') dateRangePicker!: MatDateRangePicker<any>;

  @ViewChildren('headerCells') headerCells!: QueryList<ElementRef>;

  dateRange: FormGroup;
  statusFilters: TreeNode[];
  DateColumnKey: string;
  unLoadDateFilterDate: Date[] = null;
  loadDateFactFilterDate: Date[] = null;
  unLoadDateFactFilterDate: Date[] = null;
  createdAtDateFilterDate: Date[] = null;

  constructor(private fb: FormBuilder, private dialog : MatDialog) {
    this.noItemsTitle = 'COMMON.NO_ITEMS';
    this.dateRange = this.fb.group({
      start: [''],
      end: ['']
    });
  }

  ngAfterViewInit()
  {
    if (this.paginator) {
      this.paginator.page
          .pipe(
              tap(() => this.getPageItems())
          )
          .subscribe();
    }

    if (this.sort) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      merge(this.sort.sortChange, 0)
          .pipe(
              tap(() => this.getPageItems())
          )
          .subscribe();
    }

    this.statusFilters = this._tabledata.getStatusFilterData();
  }
  // Массив данных для таблицы
  selectedCustomers = [];
  selectedAuthors = [];
  selectedSuppliers = [];

  onTreeNodeChange(updatedNode: TreeNode): void {
    this.checkTreeNodes(this.statusFilters);
  }

  addStatusFilter(filters: string[][], child: TreeNode): boolean {
    interface KeyValue {
      [key: string]: any;
    }
    let rowsKeys:KeyValue = {};

    rowsKeys['Принят'] = 'accepted';
    rowsKeys['В очереди'] = 'queued';
    rowsKeys['Не принят'] = undefined;

    rowsKeys['Отказался'] = 'True';

    rowsKeys['Согласован'] = 'agree';
    rowsKeys['Не согласован'] = 'not_agree';

    rowsKeys['Выехал под загрузку'] = '0';
    rowsKeys['Прибыл в пункт загрузки'] = '1';
    rowsKeys['Загрузился'] = '2';
    rowsKeys['Выехал в пункт разгрузки'] = '3';
    rowsKeys['Прибыл в пункт разгрузки'] = '4';
    rowsKeys['Завершенные'] = '5';

    switch (child.name) {
      case 'Завершенные':
      case 'Выехал под загрузку':
      case 'Прибыл в пункт загрузки':
      case 'Загрузился':
      case 'Выехал в пункт разгрузки':
      case 'Прибыл в пункт разгрузки':
      { // Если один из статусов "в процессе"
        if(child.checked) {
          filters[0].push(rowsKeys[child.name]);
          return true;
        }
        break;
      }

      case 'Согласован':
      case 'Не согласован':
      { // Если олин ищ статусов "согласован"
        if(child.checked) {
          filters[1].push(rowsKeys[child.name]);
        }
        break;
      }

      case 'Отказался':
      {
        if(child.checked) {
          filters[2].push(rowsKeys[child.name]);
        }
        break;
      }

      case 'В очереди':
      case 'Не принят':
      case 'Принят':
      {
        if(child.checked) {
          filters[3].push(rowsKeys[child.name]);
        }
        break;
      }
    }
    return false;
  }

  checkTreeNodes(children: TreeNode[]): void {
    this.applyAllPageFilters()
  }

  getCustomers() {
    return this.dataSource.dataCustomers.getValue().map(customer => ({
      id: customer.id,
      name: customer.get('name'),
    }));
  }

  getSuppliers() {
    return this.dataSource.dataSuppliers.getValue().map(supplier => ({
      id: supplier.id,
      name: supplier.get('name'),
    }));
  }

  getAuthors() {
    return this.dataSource.dataAuthors.getValue();
  }

  resetDateComponent() {
    this.dateRange =this.fb.group({
      start: [''],
      end: ['']
    });
  }

  setDateComponentValue(startDate, endDate) {
    this.fb.group({
      start: [startDate],
      end: [endDate]
    });
  }

  onColumnClick(column: any, event: MouseEvent): void {
    if(this.isSortByDateDisabled(column))
      return;
    const target = event.target as HTMLElement;
    const rect = target.getBoundingClientRect();

    const top = rect.bottom + window.scrollY;
    const left = rect.left -300;

    switch(column.key)
    {
      case TableNameKeys.createdAt:
      case TableNameKeys.loadDateFact:
      case TableNameKeys.unloadDateFact:
      case TableNameKeys.unloadDate:
      {
        this.DateColumnKey = column.key;
        const hiddenInput = this.hiddenInput.nativeElement as HTMLElement;
        hiddenInput.style.position = 'absolute';
        hiddenInput.style.top = `${top}px`;
        hiddenInput.style.left = `${left}px`;
        hiddenInput.style.visibility = 'visible';

        if(this.DateColumnKey == TableNameKeys.unloadDate) {
          if(this.unLoadDateFilterDate != null)
            this.setDateComponentValue(this.unLoadDateFilterDate[0], this.unLoadDateFilterDate[1]);
          else
            this.resetDateComponent();
        }
        else if(this.DateColumnKey == TableNameKeys.unloadDateFact) {
          if(this.unLoadDateFactFilterDate != null)
            this.setDateComponentValue(this.unLoadDateFactFilterDate[0], this.unLoadDateFactFilterDate[1]);
          else
            this.resetDateComponent();
        }
        else if(this.DateColumnKey == TableNameKeys.loadDateFact) {
          if(this.loadDateFactFilterDate != null)
            this.setDateComponentValue(this.loadDateFactFilterDate[0], this.loadDateFactFilterDate[1]);
          else
            this.resetDateComponent();
        }
        else if(this.DateColumnKey == TableNameKeys.createdAt) {
          if(this.createdAtDateFilterDate != null)
            this.setDateComponentValue(this.createdAtDateFilterDate[0], this.createdAtDateFilterDate[1]);
          else
            this.resetDateComponent();
        }

        this.dateRangePicker.open();
        break;
      }
      case TableNameKeys.customer: {
        const targetElement = event.currentTarget as HTMLElement;
        const dialogRef = this.dialog.open(CustomerSelectComponent, {
          backdropClass: 'overlay-backdrop',
          data: {
            elementRef: targetElement,
            selectedCustomers: this.selectedCustomers,
            filteredCustomers: this.getCustomers()
          },
        });

        dialogRef.componentInstance.selectionChange.subscribe(updatedSelection => {
          this.selectedCustomers = updatedSelection;
          this.applyAllPageFilters();
        });
        break;
      }
      case TableNameKeys.author:
      {
        const hiddenAuthor = event.currentTarget as HTMLElement;
        const dialogRef = this.dialog.open(CustomerSelectComponent,{
          backdropClass: 'overlay-backdrop',
          data : {
            elementRef: hiddenAuthor,
            selectedCustomers: this.selectedAuthors,
            filteredCustomers: this.getAuthors()
          },
        });
        dialogRef.componentInstance.selectionChange.subscribe(updatedSelection => {
          this.selectedAuthors = updatedSelection;
          this.applyAllPageFilters();
        });
        break;
      }
      case TableNameKeys.supplier:
      {
        const hiddenAuthor = event.currentTarget as HTMLElement;
        const dialogRef = this.dialog.open(CustomerSelectComponent,{
          backdropClass: 'overlay-backdrop',
          data : {
            elementRef: hiddenAuthor,
            selectedCustomers: this.selectedSuppliers,
            filteredCustomers: this.getSuppliers()
          },
        });
        dialogRef.componentInstance.selectionChange.subscribe(updatedSelection => {
          this.selectedSuppliers = updatedSelection;
          this.applyAllPageFilters();
        });
        break;
      }
      case TableNameKeys.driverStatus: {
        const hiddenStatus = event.currentTarget as HTMLElement;
        const dialogRef = this.dialog.open(HierarchicalCheckboxComponent, {
          backdropClass: 'overlay-backdrop',
          data: {
            elementRef: hiddenStatus,
            treeData: this.statusFilters,
          },
        });

        // Подписка на обновления изменений узлов
        const componentInstance = dialogRef.componentInstance;
        componentInstance.nodeChange$.subscribe(updatedNode => {
          this.onTreeNodeChange(updatedNode); // Реагируем на изменения
        });
        break;
      }

    }
  }

  reloadData() {
    if (this.dataSource !== undefined) {
      if (this.paginator !== undefined) {
        let pageSize = this._tabledata.getPageSizes()[0];
        this.paginator.pageSize = pageSize;
      }
      this.getPageItems();
    }
  }

  resetDate(dateRangePicker: MatDateRangePicker<Date>) {
    if(this.DateColumnKey == TableNameKeys.unloadDate) {
      this.unLoadDateFilterDate = null;
    }
    else if(this.DateColumnKey == TableNameKeys.unloadDateFact) {
      this.unLoadDateFactFilterDate = null;
    }
    else if(this.DateColumnKey == TableNameKeys.loadDateFact) {
      this.loadDateFactFilterDate = null;
    }
    else if(this.DateColumnKey == TableNameKeys.createdAt) {
      this.createdAtDateFilterDate = null;
    }

    this.resetDateComponent();
    this.applyAllPageFilters();
  }

  filtersReset() {
    if(this.statusFilters) // Проверяем что статусы есть на этой странице
    {
      this.statusFilters.forEach(child => {
        child.checked = false;
        if (child.children) {
          if (child.children.length > 0) {
            child.children.forEach(child_two_level => {
              child_two_level.checked = false;
            })
          }
        }
      })
    }
    // Даты
    this.unLoadDateFilterDate = null;
    this.unLoadDateFactFilterDate = null;
    this.loadDateFactFilterDate = null;
    this.createdAtDateFilterDate = null;

    //Авторы, покупатели, поставщики
    this.selectedCustomers = [];
    this.selectedAuthors = [];
    this.selectedSuppliers = [];

    this.setFilters([]);
  }

  refresh() {
    if (this.dataSource !== undefined) {
      this.dataSource.refresh();
    }
  }
  showDateRangeSelector: boolean = true; // Управляет отображением компонента

  onDateRangeSelected(): void {
    const startInput = this.dateRange.get('start').value;
    const endInput = this.dateRange.get('end').value;

    let m = moment(startInput, DATE_FORMAT);
    let dateStart = new Date(m.format());
    m = moment(endInput, DATE_FORMAT);
    let dateEnd = new Date(m.format());

    if (!(startInput && endInput)) {
      return;
    }
    dateEnd.setHours(23, 59, 59, 999); // Конечной дате ставим конец дня

    if(this.DateColumnKey == TableNameKeys.unloadDate) {
      this.unLoadDateFilterDate = [dateStart, dateEnd];
    }
    else if(this.DateColumnKey == TableNameKeys.unloadDateFact) {
      this.unLoadDateFactFilterDate = [dateStart, dateEnd];
    }
    else if(this.DateColumnKey == TableNameKeys.loadDateFact) {
      this.loadDateFactFilterDate = [dateStart, dateEnd];
    }
    else if(this.DateColumnKey == TableNameKeys.createdAt) {
      this.createdAtDateFilterDate = [dateStart, dateEnd];
    }
    this.applyAllPageFilters();
  }

  applyAllPageFilters()
  {
    let filters = [];

    // Статусы
    let statusfilters = [[], [], [], []];

    if(this.statusFilters) // Проверяем что статусы есть на этой странице
    {
      this.statusFilters.forEach(child => {
        this.addStatusFilter(statusfilters, child); //Проверяем сначала родителей
        if(child.children) {
          if (child.children.length > 0) {
            child.children.forEach(child_two_level => {
              this.addStatusFilter(statusfilters, child_two_level); //Затем детей
            })
          }
        }
      })
    }

    if(statusfilters.some(subarray => subarray.length > 0)) { //Если хотя бы один статус активен
      filters.push(new FieldFilter(
          'offers', // не используется в данном случае
          statusfilters,
          FilterCondition.StatusFilters));
    }

    // Даты
    if(this.unLoadDateFilterDate)
    {
      filters.push(new FieldFilter('unloadingBeginDate', this.unLoadDateFilterDate[0], FilterCondition.GreaterThanOrEqual));
      filters.push(new FieldFilter('unloadingBeginDate', this.unLoadDateFilterDate[1], FilterCondition.LessThanOrEqual));
    }
    if(this.unLoadDateFactFilterDate)
    {
      filters.push({
        field: 'Stage', // Значение не используется для TripStageDateRange
        value: [TripStage.Unloaded, this.unLoadDateFactFilterDate[0], this.unLoadDateFactFilterDate[1]],
        condition: FilterCondition.TripStageDateRange});
    }
    if(this.loadDateFactFilterDate)
    {
      filters.push({
        field: 'Stage', // Значение не используется для TripStageDateRange
        value: [TripStage.Loaded, this.loadDateFactFilterDate[0], this.loadDateFactFilterDate[1]],
        condition: FilterCondition.TripStageDateRange});
    }
    if(this.createdAtDateFilterDate)
    {
      filters.push(new FieldFilter('createdAt', this.createdAtDateFilterDate[0], FilterCondition.GreaterThanOrEqual));
      filters.push(new FieldFilter('createdAt', this.createdAtDateFilterDate[1], FilterCondition.LessThanOrEqual));
    }

    //Авторы, покупатели, поставщики
    if(this.selectedCustomers.length > 0)
    {
      filters.push(new FieldFilter('customer', this.selectedCustomers, FilterCondition.In));
    }
    if(this.selectedAuthors.length > 0)
    {
      filters.push(new FieldFilter('author', this.selectedAuthors, FilterCondition.In));
    }
    if(this.selectedSuppliers.length > 0)
    {
      filters.push(new FieldFilter('supplier', this.selectedSuppliers, FilterCondition.In));
    }

    this.setFilters(filters);
  }

  isFilterApplied(column) {
    const dateFilters = {
      [TableNameKeys.unloadDate]: this.unLoadDateFilterDate,
      [TableNameKeys.unloadDateFact]: this.unLoadDateFactFilterDate,
      [TableNameKeys.loadDateFact]: this.loadDateFactFilterDate,
      [TableNameKeys.createdAt]: this.createdAtDateFilterDate,
    };
    // Проверка фильтров по дате
    if (dateFilters[column.key]) {
      return true;
    }
    const checkboxFilters = {
      [TableNameKeys.customer]: this.selectedCustomers.length > 0,
      [TableNameKeys.author]: this.selectedAuthors.length > 0,
      [TableNameKeys.supplier]: this.selectedSuppliers.length > 0,
    };
    // Проверка чекбоксов
    if (checkboxFilters[column.key]) {
      return true;
    }
    // Статусы
    if(column.key === TableNameKeys.driverStatus)
    {
      if(this.statusFilters) // Проверяем что статусы есть на этой странице
      {
        for(let child of this.statusFilters)
        {
          if(child.checked) return true;
          if (child.children && child.children.length > 0) {
              for(let child_two_level of child.children) {
                if(child_two_level.checked)
                  return true;
              }
            }
          }
        }
    }
    return false;
  }

  getPageItems() {
    var offset = 0;
    var count = 0;
    if (this.paginator !== undefined) {
      offset = this.paginator.pageIndex * this.paginator.pageSize;
      count = this.paginator.pageSize;
    } else if (!this.options.hiddenPaginator) {
      count = this._tabledata.getPageSizes()[0];
    }
    if (this.sort !== undefined) {
      this.dataSource.setSort(this.sort);
    }
    this.dataSource.loadData(offset, count);
  }

  getTableData() {
    return this._tabledata;
  }

  setTableData(tableData: TableData) {
    this._tabledata = tableData;
    if (tableData) {
      this.setDataSource(tableData.provider);
    }
  }

  getDataSource(): TableDataSource {
    return this.dataSource;
  }

  setDataSource(dataSource: TableDataSource) {
    this.dataSource = dataSource;
    dataSource.data.subscribe(() => {
      this.checkSelection();
    });
    this.reloadData();
  }

  search(search: string) {
    this.dataSource.setSearchFilter(search);
    this.reloadData();
  }

  setFilters(filters: FieldFilter[]) {
    this.dataSource.setOtherFilters(filters);
    this.reloadData();
  }
  setNodeTreeFilters(filters: FieldFilter[]) {
    this.dataSource.setNodeTreeFilters(filters);
    this.reloadData();
  }

  isSortDisabled(column) {
    return !this._tabledata.canSortColumn(column);
  }

  isSortByDateDisabled(column) {
    return this._tabledata.canSortByDateColumn(column);
  }

  displayedColumns() {
    var cols = this._tabledata.displayedColumns.slice();
    if (this.options.selectable) {
      cols.splice(0, 0, 'select');
    }
    if (this.options.dragable) {
      cols.push('drag');
    }
    return cols;
  }

  selectedValues(): any[] {
    return this.selection.getSelected();
  }

  private checkSelection() {
    let selectedBefore = this.selectedValues();
    let newRows = this.dataSource.getData().map(o => o.id);
    let rowsToDeselect = [];
    selectedBefore.forEach(row => {
      let index = newRows.indexOf(row.id);
      if (index == -1) {
        rowsToDeselect.push(row);
      }
    });
    this.deselect(rowsToDeselect);
  }

  deselect(rows: any[]) {
    let selectedBefore = this.selectedValues();
    rows.forEach(row => {
      this.selection.deselect(row);
    });
    let selectedAfter = this.selectedValues();
    if (selectedAfter != selectedBefore) {
      this.onSelectionChange.emit({
        data: rows,
        table: this
      });
    }
  }

  select(row: any) {
    this.selection.select(row);
    this.onSelectionChange.emit({
      data: row,
      table: this
    });
  }

  deselectAll() {
    this.deselect(this.dataSource.getData());
    this.selection.clear();
  }

  masterToggle() {
    if (this.selection.getSelected().length > 0) {
      this.deselectAll();
    } else {
      this.dataSource.getData().forEach(row => {
        if (!this.isDisabled(row)) {
          this.select(row);
        }
      });
    }
  }

  isWarnRow(row): boolean {
    return this._tabledata.isWarnRow(row);
  }

  isMarkDeleted(row): boolean {
    return this._tabledata.isMarkDeleted(row);
  }

  isDisabled(row): boolean {
    return this.isMarkDeleted(row) || this.disabled;
  }

  public toggle(item) {
    if (this.options.singleSelection) {
      this.deselectAll();
    }
    this.selection.toggle(item);
    this.onSelectionChange.emit({
      data: item,
      table: this
    });
  }

  private clickRow(row) {
    let shouldSelect = true;
    if (this.selection.getSelected().length === 1) {
      let selectedRow = this.selection.getSelected()[0];
      if (selectedRow === row) {
        shouldSelect = false;
      }
    }

    if (this.isDisabled(row)) {
      shouldSelect = false;
    }

    this.deselectAll();
    if (shouldSelect) {
      this.select(row);
    }
  }

  private handleDblClick(row, table) {
    this.rowDoubleClick.emit({
      data: row,
      table: table
    });
  }

  isSelected(row) {
    return this.selection.isSelected(row);
  }

  isSelectedSameAsDisplayed(): boolean {
    let selected = this.selection.getSelected();
    var displayed = this.dataSource.getData();

    if (!selected || !displayed) {
      return false;
    }

    if (selected.length == 0) {
      return false;
    }

    if (selected.length != displayed.length) {
      return true;
    }
    let diff = _.difference(selected, displayed);
    return diff.length != 0;
  }

  private _timer;
  private _delay = 200;
  private _prevent = false;

  singleClick(row) {
    let handler = this;
    this._timer = setTimeout(function () {
      if (!handler._prevent) {
        handler.clickRow(row);
      }
      handler._prevent = false;
    }, handler._delay);
  }

  doubleClick(row) {
    let table = this;
    clearTimeout(this._timer);
    this._prevent = true;
    this.handleDblClick(row, table);
  }

  getLastError() {
    return this.dataSource ? this.dataSource.getLastLoadError() : undefined;
  }

  isLoading(): boolean {
    return this.dataSource && this.dataSource.isLoading();
  }

  hasData(): boolean {
    return this.getTableData() && this.dataSource && this.dataSource.hasData();
  }

  drop(event: CdkDragDrop<any[]>) {
    let ds = this.getDataSource();
    let items = ds.getData();
    moveItemInArray(items, event.previousIndex, event.currentIndex);
    ds.data.next(items);
  }
}

class DefaultTableSelection implements TableSelection {
  private selection = new SelectionModel<any>(true, []);
  select(row: any) {
    this.selection.select(row);
  }

  deselect(row: any) {
    this.selection.deselect(row);
  }

  getSelected() {
    return this.selection.selected;
  }

  toggle(row) {
    this.selection.toggle(row);
  }

  isSelected(row): boolean {
    return this.selection.isSelected(row);
  }

  clear() {
    this.selection.clear();
  }
}

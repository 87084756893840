import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { OrdersListTableData } from './orders-list-tabledata';

import {defaultTableOptions, TableComponent, TableOptions} from 'app/components/table/table.component';

import { DispatcherOrderWriteAccess } from 'app/components/access/dispatcher-order-write';

import { OrdersDialogPresenterService } from 'app/main/orders/dialog/ordersdialogspresenter/orders-dialog-presenter.service';

import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'app/components/locale/locale.service';
import { orderlist, orders_row_operations, orderinfo } from '../../locale/locale';
import { default_row_operations, orders, common, tripStage, offers, equipment } from '../../../locale/locale';
import { TableData } from 'app/components/table/tabledata';
import { TableOperationsController } from 'app/components/table-operations-controller/table-operations-controller';

import { OrdersListTableOperationsFactory } from './orders-list.operation-factory';

const locales = [
  orderlist,
  orders,
  common,
  default_row_operations,
  orders_row_operations,
  orderinfo,
  tripStage,
  offers,
  equipment
];

@Component({
  selector: 'orders-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class OrdersListComponent implements DispatcherOrderWriteAccess.TableOperationUpdating {
  tableOptions: TableOptions;
  tableData: TableData;
  headerButtons = [];

  operationsController: TableOperationsController;
  @ViewChild(TableComponent) private table: TableComponent;

  constructor(
    private localeService: LocaleService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private dialogPresenter: OrdersDialogPresenterService
  ) {
    this.localeService.loadLocales(locales);

    this.tableData = new OrdersListTableData(this.translate);
    this.tableOptions = defaultTableOptions();
    this.tableOptions.alwaysVisibleHeader = true;
    this.headerButtons = this.makeHeaderButtons();
    let operationsFactory = new OrdersListTableOperationsFactory(
      this.dialogPresenter
    );
    this.operationsController = new TableOperationsController(operationsFactory);
  }

  ngAfterViewInit() {
    DispatcherOrderWriteAccess.checkTableOperations(this);
  }

  search(searchString: string) {
    this.table.search(searchString);
  }

  updateOperations() {
    this.operationsController.updateOperations(this.table);
  }

  private reloadData() {
    this.table.reloadData();
    DispatcherOrderWriteAccess.reloadTableOperations(this);
  }

  private filtersReset() {
    this.table.filtersReset();
  }


  private makeHeaderButtons() {
    return [
      {
        'title': 'COMMON.BUTTONS.RELOAD',
        'action': () => this.reloadData()
      },
      {
        'title': 'COMMON.BUTTONS.RESET_FILTERS',
        'action': () => this.filtersReset()
      }
    ];
  }
}

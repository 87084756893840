export enum TableNameKeys {
    number = 'number',
    tonnage = 'tonnage',
    customer = 'customerName',
    author = 'author',
    supplier = 'supplier',
    unLoadingPoint = 'outpoint',
    brand = 'brand',
    equipment = 'equipment',
    driver = 'driver',
    customerStatus = 'customerStatus',
    driverStatus = 'driver_status',
    unloadDate = 'unloadingBeginDate',
    loadDateFact = 'loadingDateFact',
    unloadDateFact = 'unLoadingDateFact',
    createdAt = 'createdAt',
    comment = 'comment',
}
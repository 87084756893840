import { NgModule } from '@angular/core';
import { HierarchicalCheckboxComponent} from './hierarchical-checkbox.component';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/main/material.module';
import {MatDialogModule} from "@angular/material/dialog";

@NgModule({
    declarations: [
        HierarchicalCheckboxComponent,
    ],
    imports: [
        FuseSharedModule,
        TranslateModule,
        MaterialModule,
        MatDialogModule,
    ],
    exports: [
        HierarchicalCheckboxComponent,
    ]
})

export class HierarchicalCheckboxModule
{
}

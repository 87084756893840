import { Component, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { ClickData } from 'app/components/table/table.component';
import { PFObject } from 'app/components/data/core/models/base/object';
import { UnloadingPoint } from 'app/components/data/core/models/points/points';
import { Customer } from 'app/components/data/core/models/persons/customer';

import { EntranceDialog } from '../../entrance/entrance-dialog.component';
import { EntranceTableData } from '../../entrance/entrance.tabledata';
import { DialogPresenterService } from 'app/components/dialogs/dialog-presenter/dialog-presenter.service';

import { LocaleService } from 'app/components/locale/locale.service';
import { customer, points } from 'app/main/admin/users/locale/locale';
import { ActionBarOperation } from 'app/components/action-bar/action-bar.component';
import { TableOperationsHelper } from 'app/components/helpers/table-operations.helper';

let locales = [
  customer,
  points
];

@Component({
  selector: 'customer-edit-entrances-dialog',
  templateUrl: './customer-edit-entrances-dialog.component.html',
  styleUrls: ['./customer-edit-entrances-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CustomerEditEntrancesDialogComponent {
  compareFunction = PFObject.compareFn;

  public form: UntypedFormGroup;
  public object: Customer;

  tableData: EntranceTableData;
  tableOperations: ActionBarOperation[] = [];
  filteredPoints: UnloadingPoint[] = [];

  constructor(
    private localeService: LocaleService,
    public dialogRef: MatDialogRef<CustomerEditEntrancesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: UntypedFormBuilder,
    private dialogPresenterService: DialogPresenterService
  ) {
    this.localeService.loadLocales(locales);
    this.object = this._data.object;
    this.form = this.createEntrancesForm();
    this.tableOperations = this.makeTableOperations([]);

    let points = this.getPoints();
    let firstPoint = points && points.length > 0 ? points[0] : null;
    this.form.controls.unloadingPoint.setValue(firstPoint);
    this.updateTableData(firstPoint);
  }

  ngOnInit() {
    this.form.get('unloadingPoint').valueChanges.subscribe(value => {
      this.filteredPoints = this.filterPoints(value); // Обновляем массив
    });
  }

  private filterPoints(value: string | UnloadingPoint): UnloadingPoint[] {
    const filterValue = typeof value === 'string' ? value.toLowerCase() : value.getAddress().toLowerCase();
    return this.getPoints().filter(point => point.getAddress().toLowerCase().includes(filterValue));
  }

  pointDisplay(point: UnloadingPoint): string {
    return point ? point.getAddress() : '';
  }

  doubleClickHandler(click: ClickData) {
    this.showEntranceDialog('edit', click.data);
  }

  updateOperations(value: ClickData) {
    let selected = value.table.selectedValues();
    this.tableOperations = this.makeTableOperations(selected);
  }

  private makeTableOperations(selected): ActionBarOperation[] {
    return [
      TableOperationsHelper.makeCreateOperation(() => this.addAddress()),
      TableOperationsHelper.makeEditOperation(() => this.editAddress(selected), selected),
      TableOperationsHelper.makeRemoveOperation(() => this.removeAddress(selected), selected),
    ];
  }

  private addAddress() {
    this.showEntranceDialog('create', null);
  }

  private editAddress(selected) {
    this.showEntranceDialog('edit', selected[0]);
  }

  private removeAddress(selected) {
    this.showRemoveDialog(selected);
  }

  private showRemoveDialog(selected) {
    let point = this.getPoint();
    let handler = this;
    this.dialogPresenterService.askRemoveItems(selected, (error) => {
      if (!error) {
        selected.forEach((entrance) => {
          point.removeEntrance(entrance);
        });
        point.save().then(success => {
          if (success) {
            handler.updateTableData(point);
          }
        });
      }
    });
  }

  getPoint(): UnloadingPoint {
    return this.form.controls.unloadingPoint.value;
  }

  getPoints(): UnloadingPoint[] {
    return this.object.getUnloadingPoints();
  }

  private showEntranceDialog(actionName, item) {
    let point = this.getPoint();
    let data = new Map<string, any>();
    data['action'] = actionName;
    data['object'] = item;
    data['address'] = point.getAddress();
    let handler = this;
    this.dialogPresenterService.showDialog({
      dialog: EntranceDialog,
      panel: 'entrance-dialog-container',
      data: data,
    }, function (entrance) {
      if (entrance) {
        if (actionName === 'create') {
          point.addEntrance(entrance);
        }
        point.save().then(success => {
          if (success) {
            handler.updateTableData(point);
          }
        });
      }
    });
  }

  updateTableData(point) {
    if (point) {
      this.tableData = new EntranceTableData(point);
    }
  }

  private createEntrancesForm(): UntypedFormGroup {
    return this._formBuilder.group({
      unloadingPoint: null,
    });
  }
}
